<template>

 <div class="contact">
    <div class="contact__left">
    <form class="vue-form" @submit.prevent="submit">
        <fieldset>
        <div>
            <label class="label" for="name">Name</label>
            <input type="text" name="name" id="name" required="" v-model="name">
        </div>
        <div>
            <label class="label" for="email">Email</label>
            <input type="email" name="email" id="email" required=""
                :class="{ email , error: !email.valid }"
                v-model="email.value">
            <div class="error-message">
            <p v-show="!email.valid">Please enter a valid email address.</p>
            </div>
        </div>


        <div>
            <label class="label" for="textarea">Message</label>
            <span class="counter">{{ message.text.length }} / {{ message.maxlength }}</span>
            <textarea class="message" name="textarea" id="textarea" required="" 
                    v-model="message.text" 
                    :maxlength="message.maxlength"></textarea>
        </div>
        <div>
            <input type="submit" value="Send">
        </div>
        </fieldset>
     </form>

     <p v-show="response" :class="{ 
       'response-msg' : true, 
       'failure': response && response.status === 'failure',
       'success': response && response.status === 'success',
       
       }">{{ response.msg }}</p>

    </div>

    <div class="contact__right">
        <div class="contact__right__header">
            <div>
              <h2>Let's talk</h2>
               <p> Do you have questions or cooperation proposals?</p>
               <p>Contact me via <span>social media</span> or directly via the <span>contact form</span>.</p>
               <p>I usually reply within <span>24 hours</span></p>
            </div>
        </div>

        <ul class="contact__right__socials">
          <li v-for="{url, title, portal} in socials" :key="portal">
            <a :href="url" target="_blank">
              <IconFacebook v-if="portal === 'facebook'" />
              <IconYoutube v-else-if="portal === 'youtube'" />
              <IconSoundcloud v-else-if="portal === 'soundcloud'" />
              <IconInstagram v-else-if="portal === 'instagram'" />
              {{ title }}
              </a>
          </li>
        </ul>
    </div>

 </div>
</template>


<script>
//eslint-disable-next-line
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

import { SocialsService, EmailService } from "@/api";
import IconFacebook from '@/assets/icons/IconFacebook.vue'
import IconYoutube from '@/assets/icons/IconYoutube.vue'
import IconSoundcloud from '@/assets/icons/IconSoundcloud.vue'
import IconInstagram from '@/assets/icons/IconInstagram.vue'




export default {
    name: 'Contact',
    components: {
        IconFacebook,
        IconYoutube,
        IconSoundcloud,
        IconInstagram
    },
  data() {
    return {
      name: "",
      email: {
        value: "",
        valid: true
      },
      message: {
        text: ``,
        maxlength: 1000
      },
      submitted: false,
      socials: [],
      response: false
    };
  },
  created() {
    this.getSocials();
  },
  methods: {       
     async getSocials() {
        await SocialsService.getAll()
          .then(res => {        
              this.socials = res;
          })
    },
    async submit() {
      this.submitted = true;

      const data = {
        name: this.name,
        email: this.email.value,
        message: this.message.text
      }

      await EmailService.sendEmail(data).then((res) => {
        this.response = res;
      })
    },
    validate: function(type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    isEmail: function(value) {
      return emailRegExp.test(value);
    },
  },
  watch: {
    "email.value": function(value) {
      this.validate("email", value);
    }
  }
}
</script>


<style lang="scss" scoped>
    .contact {
      display: flex;
      flex-direction: column-reverse;
        gap: 8rem;
        width: 86%;
        max-width: 1080px;
        margin: 0 auto;
        padding: 40px 0px 40px;
        z-index: 1;


        @include mq($to: mobile) {
            width: 92%;
        }

        @include mq($from: tablet) {
        display: grid;

            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &__left {
            fieldset {
            border: none;

            & > div {
              position: relative;
            }

            label {
                display: block;
                @include fontPoppins;
            }

            input[type="text"],
            input[type="email"],
            input[type="submit"],
            textarea {
                font-family: 'Montserrat';
                background: rgba(0, 0, 0, 0.2);
                border: none;
                padding: 1.25rem;
                border-radius: 8px;
                color: $white;
                display: block;
                margin: 1rem 0px 2rem;
                width: 100%;
                // max-width: 100%;
                outline: none;

                &:active {
                  outline: none;
                }
            }

            input[type="submit"] {
              max-width: 240px;
              min-width: 160px;
              text-transform: uppercase;
              color: $main;
              cursor: pointer;
              padding: 2rem;
              transition: color .2s, background-color .2s;

              &:hover {
                color: $white;
                background: rgba(0, 0, 0, 0.3);

              }
            }

            textarea {
                width: 100%;
                min-height: 240px;
            }

            .error-message {
              @include fontMontserrat;
              font-size: 12px;
              color: $main;
              transform: translateY(-10px);
            }

            .counter {
              position: absolute;
              right: 0;
              top: 1rem;
              @include fontMontserrat;
              font-size: 1rem;
              color: $middleText;
            }

         }

         .response-msg {
           @include fontPoppins;

           &.success {
             color: $main;
           }
           &.failure {
             color: rgb(177, 0, 0);
           }
         }
        }

        &__right {
            &__header {
                display: flex;
                gap: 0rem;
                align-items: center;

                & > div:first-of-type {
                  width: 100%;
                }
            

                h2 {
                    font-family: 'Poppins';
                    color: $lightText;
                    font-weight: 500;
                    font-size: 2rem;
                    margin-bottom: 4rem;
                    text-align: center;
                    opacity: 0.5;

                    @include mq($from: tablet) {
                      text-align: left;
                    }
                }

                p {
                     font-family: 'Montserrat';
                    color: $middleText;
                    font-size: 1.4rem;
                    font-weight: 300;
                    margin-top: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.4px;
                    text-align: center;

                    @include mq($from: tablet) {
                      text-align: left;
                    }

                    span {
                      color: $main;
                      opacity: 0.6;
                    }
                }


            }

            &__socials {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              // display: flex;
              // flex-direction: column;
              row-gap: 4rem;
              margin-top: 5rem;

              li {
                list-style: none;
                display: flex;
                justify-content: center;

                @include mq($from: tablet) {
                  justify-content: flex-start;
                }
              }

                a {
                  @include fontPoppins;
                  display: flex;
                  align-items: center;
                  gap: 1.5rem;
                  color: $lightText;
                  opacity: 0.75;
                  transition: opacity .22s;

                 

                  &:hover {
                    opacity: 1;
                  }

                  svg {
                    width: 32px;
                  }
                }
            }
        }
    }

</style>