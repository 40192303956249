<template>
    <section>
        <Breadcrumbs/>

       <div class="wrapper">
            <Contact />
       </div>
        
    </section>
</template>
<script>
// import { mapState } from 'vuex';
import Contact from '@/components/Contact'

export default {
    name: 'ContactPage',
     components: {
        Contact
    },
    // computed: {
    //     ...mapState({
    //         favourites: (state) => {
    //             const allBeats = state.beats.all;
    //             const favouritesIDs = state.favourites.favourites;

    //             const favourites = allBeats ? allBeats.filter(beat => {
    //                 return favouritesIDs.includes(beat.id)
    //             }) : []

    //             return favourites;    
    //         },
    //     }),
    // }
}
</script>
<style lang="scss" scoped>
    section {
        @include sectionStarter;
        margin-top: 8rem;
        padding-top: 4rem;
        min-height: calc(100vh - 130px);

        & h1 {
            @include fontPoppins;
            color: $lightText;
            font-size: 3rem;
            letter-spacing: .1em;
            z-index: 1;
            margin-top: 6rem;
        }

        .wrapper {
            margin-top: 2rem;
        }

        .contact {
            width: 100%;
            margin: 0px;
            max-width: unset;
        }
    }
</style>